import { getUrlParameter, setReferenceUrl } from "../shared";
// Javascript that runs on the score card generator's ballet page

export default {
  // The function that runs when the JS is intialized
  init: function() {
    this.applyData();
    this.bindPrintButton();
    setReferenceUrl("Ballet");
  },

  /**
   * The name of the festival
   * @type {String}
   */
  festivalName: getUrlParameter("festival-name"),

  /**
   * The class type.
   * (Novice, Experienced, or Masters)
   * @type {String}
   */
  classType: getUrlParameter("class"),

  /**
   * The number of lines used in the competition
   * (Dual-Line or Multi-Line)
   * @type {String}
   */
  lines: getUrlParameter("lines"),

  /**
   * The number of competitors
   * (Individual, Pair, Team (3 Person), Team (4 Person), Team (5 Person))
   * @type {String}
   */
  competitor: getUrlParameter("competitor"),

  // Applies the data provided in the URL to the page
  applyData: function() {
    $(".js_festival-name").text(this.festivalName);
    $(".js_discipline").text(this._getDiscipline());
  },

  /**
   * Binds the .js_print element so that it triggers the print dialog when clicked.
   */
  bindPrintButton: function() {
    $(".js_print").on("click", () => window.print());
  },

  // Builds the discipline title
  _getDiscipline: function() {
    var disciplineClass = this.classType || "";
    var competitor = this.competitor || "";
    var lines = this.lines || "";
    return disciplineClass + " " + competitor + " " + lines + " Ballet";
  }
};
