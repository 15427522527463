export default {
  /**
   * All precision figures.
   * Populated by loadFigures()
   * @type {Array}
   */
  figures: [],

  /**
   * The figures that are relevant to the current selections.
   * Populated by handleMainFormChange()
   * @type {Array}
   */
  relevantFigures: [],

  // The function that runs when the JS is intialized
  init() {
    // Disable all select fields and the submit button until precision figures are loaded
    $("select, button").attr("disabled", true);

    // Load in the precision figures
    this.loadFigures().then(() => {
      // Reenable the fields
      $("select, button").attr("disabled", null);

      // Watch submission
      $(".js_form").on("submit", this.handleFormSubmission);

      // Watch for changes in any field in the main form so that we can add/remove precision form fields
      $(".js_main-fields select").on(
        "change",
        this.handleMainFormChange.bind(this)
      );
      this.handleMainFormChange();

      // Watch for changes in the event field to add/remove the additional form fields
      $(".js_event-dropdown").on("change", this.handleEventChange.bind(this));
      this.handleEventChange();
    });
  },

  /**
   * Loads the precision figures
   * @return {Promise}
   */
  loadFigures() {
    let figuresPromise = $.ajax("/data/figures.json", {
      method: "GET",
      dataType: "text json"
    });
    figuresPromise.fail((promise, error) =>
      console.error("Failed to retrieve figures", error)
    );
    return figuresPromise.then(response => {
      this.figures = response.figures;
      return response;
    });
  },

  /**
   * Handle the form submission to redirect to the right place
   */
  handleFormSubmission(e) {
    // Prevent a regular form submission
    e.preventDefault();

    // Get the data from the form
    var data = $(this).serializeArray();

    // Determine what event type has been selected
    var eventType = data.find(item => item.name === "event").value;

    // Remove unnecessary data that doesnt need to be sent to the next page
    data = data.filter(item => {
      // No need to send the event to the next page since each page is specific to an
      // event already
      if (item.name === "event") {
        return false;
      }

      // Remove data that some events don't need
      if (
        [
          "indoor-unlimited",
          "outdoor-unlimited",
          "indoor-single-line"
        ].includes(eventType)
      ) {
        return !["class", "lines", "competitor"].includes(item.name);
      }

      return true;
    });

    // Go to the correct page
    let origin = window.location.origin;
    let serializedData = data.reduce((serializedString, item) => {
      if (serializedString) {
        return `${serializedString}&${item.name}=${item.value}`;
      }
      return `?${item.name}=${item.value}`;
    }, "");
    window.location.href =
      origin + `/templates/${eventType}.html` + serializedData;
  },

  /**
   * Handles when the main form fields change.
   */
  handleMainFormChange() {
    const selectedEvent = $(".js_event-dropdown").val();
    const selectedLines = $(".js_lines-dropdown").val();
    const selectedCompetitor = $(".js_competitor-dropdown").val();
    const showFigureDropdowns = [
      selectedEvent === "precision",
      !!selectedLines,
      !!selectedCompetitor
    ].every(condition => condition);
    if (showFigureDropdowns) {
      // Get the figures that are relevant given the user's selection
      const linesPrefix = selectedLines === "Dual-Line" ? "D" : "M";
      const competitorPrefixMap = {
        Individual: "I",
        Pairs: "P",
        "Team (3 Person)": "T",
        "Team (4 Person)": "T",
        "Team (5 Person)": "T"
      };
      const competitorPrefix = competitorPrefixMap[selectedCompetitor];
      const discipline = `${linesPrefix}${competitorPrefix}`;
      this.relevantFigures = this.figures.filter(figure => {
        return figure.discipline === discipline;
      });

      // Build the figure dropdowns
      this.buildFigureDropdowns();

      // Handle changes to the fields to prevent selecting the same figure more than once
      $(".js_figures-dropdown").on("change", this.handleFigureDropdownChange);
      return;
    }

    // Destroy the figure dropdowns
    $(".js_figures-dropdown").off("change", this.handleFigureDropdownChange);
    $(".js_figure-fields").html("");
  },

  /**
   * Handles when the event form field changes
   */
  handleEventChange() {
    const selectedEvent = $(".js_event-dropdown").val();
    const eventConfigs = {
      "indoor-unlimited": {
        "class-field": false,
        "lines-field": false,
        "competitor-field": false
      },
      "outdoor-unlimited": {
        "class-field": false,
        "lines-field": false,
        "competitor-field": false
      },
      "indoor-single-line": {
        "lines-field": false,
        "class-field": false,
        "competitor-field": false
      },
      default: {
        "class-field": true,
        "lines-field": true,
        "competitor-field": true
      }
    };
    const currentConfig = eventConfigs[selectedEvent] || eventConfigs.default;
    Object.keys(currentConfig).forEach(key => {
      if (currentConfig[key]) {
        // Add the required property
        $(`.js_${key} select[required]`).attr("required", true);

        // Show the field
        $(`.js_${key}`).show();
      } else {
        // Remove the required property

        $(`.js_${key} select[required]`).attr("required", false);
        // Hide the field
        $(`.js_${key}`).hide();
      }
    });
  },

  /**
   * Uses `relevantFigures` to build 3 selects with figure options
   */
  buildFigureDropdowns() {
    // Get the relevant figures
    const figures = this.relevantFigures;

    // Build a template for the options
    const optionsTemplate = figures.reduce((optionsString, figure) => {
      return `${optionsString}
                <option value=${figure.id}>[${figure.id}] ${
        figure.name
      }</option>
            `;
    }, "");

    // Build the final template for the select fields
    const template = `
            <label>Select Figures</label>
            <select name="figure1" required class="js_figures-dropdown">
                <option value="">Figure 1</option>
                ${optionsTemplate}
            </select>
            <select name="figure2" required class="js_figures-dropdown">
                <option value="">Figure 2</option>
                ${optionsTemplate}
            </select>
            <select name="figure3" required class="js_figures-dropdown">
                <option value="">Figure 3</option>
                ${optionsTemplate}
            </select>
        `;
    $(".js_figure-fields").html(template);
  },

  /**
   * When a figure dropdown value changes, disable the already selected options in the other
   * selects.
   */
  handleFigureDropdownChange() {
    // Get the currently selected values from all figure dropdowns
    const selectedValues = [
      $(".js_figures-dropdown:eq(0)").val(),
      $(".js_figures-dropdown:eq(1)").val(),
      $(".js_figures-dropdown:eq(2)").val()
    ];

    // Update each figure dropdown
    $(".js_figures-dropdown").each((index, figureDropdown) => {
      // Enable all options in the dropdown
      $(figureDropdown)
        .children("option")
        .attr("disabled", false);

      // Disable each value selected by another dropdown
      selectedValues.forEach((selectedValue, selectedValueIndex) => {
        // If the selected value is for this dropdown, or is there is no selected value,
        // do nothing
        if (selectedValueIndex === index || !selectedValue) {
          return;
        }

        // Disable the option that is selected in another dropdown
        $(figureDropdown)
          .children(`[value=${selectedValue}]`)
          .attr("disabled", true);
      });
    });
  }
};
