import { getUrlParameter, buildFigures, setReferenceUrl } from "../shared";

export default {
  // The function that runs when the JS is intialized
  init() {
    this.applyData();
    this.bindPrintButton();
    setReferenceUrl("Precision");
  },

  /**
   * The name of the festival
   * @type {String}
   */
  festivalName: getUrlParameter("festival-name"),

  /**
   * The class type.
   * (Novice, Experienced, or Masters)
   * @type {String}
   */
  classType: getUrlParameter("class"),

  /**
   * The number of lines used in the competition
   * (Dual-Line or Multi-Line)
   * @type {String}
   */
  lines: getUrlParameter("lines"),

  /**
   * The number of competitors
   * (Individual, Pair, Team (3 Person), Team (4 Person), Team (5 Person))
   * @type {String}
   */
  competitor: getUrlParameter("competitor"),

  /**
   * The ID of a selected figure.
   * e.g MT01
   * @type {String}
   */
  figure1: getUrlParameter("figure1"),

  /**
   * The ID of a selected figure.
   * e.g MT01
   * @type {String}
   */
  figure2: getUrlParameter("figure2"),

  /**
   * The ID of a selected figure.
   * e.g MT01
   * @type {String}
   */
  figure3: getUrlParameter("figure3"),

  // Applies the data provided in the URL to the page
  applyData() {
    // Set the festival name
    $(".js_festival-name").text(this.festivalName);

    // Set the discipline
    $(".js_discipline").text(this._getDiscipline());

    // Determine the competitor and if we need to use an image suffix
    const imageSuffixMap = {
      "Team (3 Person)": "-3mt",
      "Team (5 Person)": "-5mt"
    };
    const imageSuffix = imageSuffixMap[this.competitor] || "";

    // Set the data for each figure
    const figureIds = [this.figure1, this.figure2, this.figure3];
    buildFigures(figureIds).then(figures => {
      figures.forEach((figure, index) => {
        // Set the image
        $(`.js_figure-image:eq(${index})`).attr(
          "src",
          `../images/${figure.id}${imageSuffix}.gif`
        );

        // Set the considerations
        const considerations = figure.critComp.concat(figure.otherComp);
        const $considerationsList = $(`.js_consider-list:eq(${index})`);
        if (!considerations.length) {
          $considerationsList.remove();
        } else {
          considerations.forEach(consideration => {
            $considerationsList.append(`<li>${consideration}</li>`);
          });
        }

        // Set the explaination
        const $explanationList = $(`.js_explanation-list:eq(${index})`);
        if (!figure.explanation.length) {
          $explanationList.remove();
        } else {
          figure.explanation.forEach(explanation => {
            $explanationList.append(`<li>${explanation}</li>`);
          });
        }
      });
    });
  },

  /**
   * Binds the .js_print element so that it triggers the print dialog when clicked.
   */
  bindPrintButton: function() {
    $(".js_print").on("click", () => window.print());
  },

  // Builds the discipline title
  _getDiscipline() {
    var disciplineClass = this.classType || "";
    var competitor = this.competitor || "";
    var lines = this.lines || "";
    return disciplineClass + " " + competitor + " " + lines + " Precision";
  }
};
