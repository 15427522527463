import { getUrlParameter, setReferenceUrl } from "../shared";
// Javascript that runs on the score card generator's indoor unlimited page

export default {
  // The function that runs when the JS is intialized
  init: function() {
    this.applyData();
    this.bindPrintButton();
    setReferenceUrl("Individual Indoor Single Line");
  },

  /**
   * The name of the festival
   * @type {String}
   */
  festivalName: getUrlParameter("festival-name"),

  /**
   * Binds the .js_print element so that it triggers the print dialog when clicked.
   */
  bindPrintButton: function() {
    $(".js_print").on("click", () => window.print());
  },

  // Applies the data provided in the URL to the page
  applyData: function() {
    $(".js_festival-name").text(this.festivalName);
  }
};
