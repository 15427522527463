import { getUrlParameter, buildFigures } from "../shared";

export default {
  init() {
    this.applyData();
    this.bindPrintButton();
  },

  /**
   * The name of the festival
   * @type {String}
   */
  festivalName: getUrlParameter("festival-name"),

  /**
   * The class type.
   * (Novice, Experienced, Masters, or Open)
   * @type {String}
   */
  classType: getUrlParameter("class"),

  /**
   * The number of lines used in the competition
   * (Dual-Line or Multi-Line)
   * @type {String}
   */
  lines: getUrlParameter("lines"),

  /**
   * The number of competitors
   * (Individual, Pair, Team (3 Person), Team (4 Person), Team (5 Person))
   * @type {String}
   */
  competitor: getUrlParameter("competitor"),

  /**
   * The discipline
   * (Ballet, Precision, Etc)
   * @type {String}
   */
  discipline: getUrlParameter("discipline"),

  /**
   * The ID of a selected figure.
   * e.g MT01
   * @type {String}
   */
  figure1: getUrlParameter("figure1"),

  /**
   * The ID of a selected figure.
   * e.g MT01
   * @type {String}
   */
  figure2: getUrlParameter("figure2"),

  /**
   * The ID of a selected figure.
   * e.g MT01
   * @type {String}
   */
  figure3: getUrlParameter("figure3"),

  /**
   * A configuration of data indexed by each discipline.
   * @type {Object}
   */
  configs: {
    precision: {
      windRange: {
        condition: "classType",
        novice: {
          mphMin: 4.4,
          mphMax: 18.6,
          kphMin: 7,
          kphMax: 30
        },
        default: {
          mphMin: 2.5,
          mphMax: 28,
          kphMin: 4,
          kphMax: 45
        }
      },
      times: {
        condition: "competitor",
        individual: [
          ["Program (Experienced, Masters)"],
          ["Setup", "3 minutes"],
          ["Routine", "1 - 4 minutes"],
          ["Standard (Novice)", ""],
          ["Setup", "3 minutes"],
          ["Each Compulsory", "45 seconds"],
          ["Between Compulsories", "45 seconds"],
          ["Between Compulsories and Technical", "90 seconds"],
          ["Technical Routine", "1 - 3 minutes"]
        ],
        default: [
          ["Setup", "4 minutes"],
          ["Each Compulsory", "45 seconds"],
          ["Between Compulsories", "45 seconds"],
          ["Between Compulsories and Technical", "90 seconds"],
          ["Technical Routine", "2 - 4 minutes"]
        ]
      }
    },
    ballet: {
      windRange: {
        condition: "classType",
        novice: {
          mphMin: 4.4,
          mphMax: 18.6,
          kphMin: 7,
          kphMax: 30
        },
        default: {
          mphMin: 2.5,
          mphMax: 28,
          kphMin: 4,
          kphMax: 45
        }
      },
      times: {
        condition: "competitor",
        individual: [["Setup", "3 minutes"], ["Routine", "2 - 4 minutes"]],
        default: [["Setup", "4 minutes"], ["Routine", "2 - 5 minutes"]]
      }
    },
    "open individual outdoor unlimited": {
      windRange: {
        condition: "classType",
        default: {
          mphMin: 4.4,
          mphMax: 18.6,
          kphMin: 7,
          kphMax: 30
        }
      },
      times: {
        condition: "competitor",
        default: [["Setup", "5 minutes"], ["Routine", "2 - 4 minutes"]]
      }
    },
    "open individual indoor unlimited": {
      disableWindCheck: true,
      windRange: null,
      times: {
        condition: "competitor",
        default: [["Setup", "3 minutes"], ["Routine", "2 - 4 minutes"]]
      }
    },
    "team train ballet": {
      windRange: {
        condition: "classType",
        default: {
          mphMin: 4.4,
          mphMax: 18.6,
          kphMin: 7,
          kphMax: 30
        }
      },
      times: {
        condition: "competitor",
        default: [["Setup", "5 minutes"], ["Routine", "2 - 5 minutes"]]
      }
    }
  },

  applyData() {
    $(".js_festival-name").text(this.festivalName);
    $(".js_discipline").text(this._getDisciplineText());

    const relevantConfig = this.configs[this.discipline.toLowerCase()];
    this._applyWindRange(relevantConfig);
    this._applyTimes(relevantConfig);
    this._applyFigures();
  },

  /**
   * Applies the wind range data to the page
   * @param  {Object} config A configuration for a particular discipline
   */
  _applyWindRange(config) {
    if (!config.windRange) {
      $(".js_wind-range").remove();
      return;
    }
    const relevantSubconfig = this._getSubConfig(config.windRange);
    $(".js_wind-range").text(
      `Wind Range: (${relevantSubconfig.mphMin} - ${
        relevantSubconfig.mphMax
      } mph) = (${relevantSubconfig.kphMin} - ${relevantSubconfig.kphMax} Km/h)`
    );
  },

  /**
   * Applies the times data to the page
   * @param  {Object} config A configuration for a particular discipline
   */
  _applyTimes(config) {
    if (!config.times) {
      $(".js_time-limits").remove();
      return;
    }
    const relevantSubconfig = this._getSubConfig(config.times);
    const html = `<table class="h-block-center">
            ${relevantSubconfig.reduce((accumulatedRows, row) => {
              return `${accumulatedRows}
                    <tr>
                        ${row.reduce((accumulatedCells, cell) => {
                          return `${accumulatedCells}
                                <td>${cell}</td>
                            `;
                        }, "")}
                    </tr>
                `;
            }, "")}
        </table>`;
    $(".js_time-limits").append(html);
  },

  /**
   * Applies the relevant figures to the page, or removes the second page, as necessary.
   */
  _applyFigures() {
    if (this.discipline.toLowerCase() !== "precision") {
      $(".js_page-2").remove();
      return;
    }
    // Determine the competitor and if we need to use an image suffix
    const imageSuffixMap = {
      "Team (3 Person)": "-3mt",
      "Team (5 Person)": "-5mt"
    };
    const imageSuffix = imageSuffixMap[this.competitor] || "";
    const figureIds = [this.figure1, this.figure2, this.figure3];
    buildFigures(figureIds).then(figures => {
      figures.forEach((figure, index) => {
        $(`.js_figure-${index + 1}`).append(
          `<img src="../images/${figure.id}${imageSuffix}.gif"/>`
        );
      });
    });
  },

  /**
   * Get the relevant subconfig for the current situation
   * @param  {subconfig} subconfig A property on the config that contains a condition and some data sets
   * @return {Object}              Returns the relevant data set
   */
  _getSubConfig(subconfig) {
    const key = this[subconfig.condition]
      ? this[subconfig.condition].toLowerCase()
      : null;
    return subconfig[key] || subconfig.default;
  },

  /**
   * Binds the .js_print element so that it triggers the print dialog when clicked.
   */
  bindPrintButton: function() {
    $(".js_print").on("click", () => window.print());
  },

  /**
   * Builds discipline text to display in the heading.
   * @return {String}
   */
  _getDisciplineText: function() {
    const disciplineClass = this.classType || "";
    const competitor = this.competitor || "";
    const lines = this.lines || "";
    const discipline = this.discipline || "";
    return `${disciplineClass} ${competitor} ${lines} ${discipline}`;
  }
};
