/**
 * Retrieves figures from figures.json and provides the figures that match the provided figureIds.
 *
 * @param {String[]} figureIds an array of figure ids
 * @returns {Promise} Resolves to an array of figures.
 */
export function buildFigures(figureIds) {
  let figuresPromise = $.ajax("/data/figures.json", {
    method: "GET",
    dataType: "text json"
  });
  const scope = this;
  figuresPromise = figuresPromise.then(response => {
    const allFigures = response.figures;
    const relevantFigures = figureIds.map(figureId => {
      const foundFigure = allFigures.find(figure => figure.id === figureId);
      if (!foundFigure) {
        console.error("No figure found for " + figureId);
      }
      return foundFigure;
    });
    return relevantFigures;
  });
  figuresPromise.fail((promise, error) =>
    console.error("Failed to retrieve figures", error)
  );
  return figuresPromise;
}

/**
 * Retrieves the data for the specified URL parameter.
 *
 * Example:
 * given url "www.example.com?foo=bar&boogers=snot"
 * getUrlParameter('boogers') === 'snot'
 * @param  {String} sParam The name of the parameter to get
 * @return {String}        The value of the parameter
 */
export function getUrlParameter(sParam) {
  var sPageURL = decodeURIComponent(window.location.search.substring(1)),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      const value = sParameterName[1] === undefined ? true : sParameterName[1];
      return value.replace(/\+/g, " ");
    }
  }
}

/**
 * Based on the provided discipline and the data in the URL, builds a link to the
 * field-director-reference page and applies it to a link with the class js_view-reference.
 *
 * @param {String} discipline One of the disciplines referenced in index.html
 */
export function setReferenceUrl(discipline) {
  // Get all the data together
  const data = {
    "festival-name": getUrlParameter("festival-name"),
    class: getUrlParameter("class"),
    lines: getUrlParameter("lines"),
    competitor: getUrlParameter("competitor"),
    discipline,
    figure1: getUrlParameter("figure1"),
    figure2: getUrlParameter("figure2"),
    figure3: getUrlParameter("figure3")
  };

  // Remove empty values
  const prunedData = Object.keys(data).reduce((accumulatedData, key) => {
    if (data[key]) {
      accumulatedData[key] = data[key];
    }
    return accumulatedData;
  }, {});

  const queryString = Object.keys(prunedData)
    .map(key => `${key}=${prunedData[key]}`)
    .join("&");
  $(".js_view-reference").attr(
    "href",
    `field-director-reference.html?${queryString}`
  );
}
